import i18next from 'i18next';

import {
  BARLEY_FIELDS,
  DISTILLATION_FIELDS,
  FERMENTATION_FIELDS,
  IMPORTER_FIELDS,
  MASHING_FIELDS,
  MATURATION_FIELDS,
  NEW_PRODUCT_SUBSTEPS_IDS,
  NEW_PRODUCT_TARGET,
  PRODUCT_INFORMATION_FIELDS,
  SELECT_TEMPLATE_FIELDS,
  TASTING_NOTES_FIELDS
} from '#models/business/NewProduct/constants';
import { TEMPLATES_OPTIONS } from '#models/business/NewProduct/selectTemplate';

i18next.addResources('en', NEW_PRODUCT_TARGET, {
  [NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE]: 'Select template',
  [NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION]: 'Information',
  [NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY]: 'Bottled By',
  [NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES]: 'Tasting Notes',
  [NEW_PRODUCT_SUBSTEPS_IDS.BARLEY]: 'Barley',
  [NEW_PRODUCT_SUBSTEPS_IDS.MASHING]: 'Mashing',
  [NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION]: 'Fermentation',
  [NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION]: 'Distillation',
  [NEW_PRODUCT_SUBSTEPS_IDS.MATURATION]: 'Maturation',
  [NEW_PRODUCT_SUBSTEPS_IDS.IMPORTER]: 'Importer'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE, {
  [SELECT_TEMPLATE_FIELDS.TEMPLATE_TYPE]: 'Template type',
  [TEMPLATES_OPTIONS.LIMITED_RELEASE]: 'Limited Release',
  [TEMPLATES_OPTIONS.SINGLE_CASK]: 'Single Cask',
  [TEMPLATES_OPTIONS.SINGLE_MALT]: 'Single Malt'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION, {
  [PRODUCT_INFORMATION_FIELDS.PRODUCT_NAME]: 'Product Name',
  [PRODUCT_INFORMATION_FIELDS.PRODUCT_DATE]: 'Product Date',
  [PRODUCT_INFORMATION_FIELDS.TOTAL_CIRCULATING_BOTTLES]: 'Total Circulating Bottles',
  [PRODUCT_INFORMATION_FIELDS.UNITS_TO_BE_CREATED]: 'Units To Be Created',
  [PRODUCT_INFORMATION_FIELDS.PRODUCT_PICTURE]: 'Product Picture'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES, {
  [TASTING_NOTES_FIELDS.TASTING_NOTES]: 'Tasting Notes',
  [TASTING_NOTES_FIELDS.NOSE]: 'Nose',
  [TASTING_NOTES_FIELDS.PALATE]: 'Palate',
  [TASTING_NOTES_FIELDS.LANGUAGE]: 'Language',
  tastingNotesSubtitle: 'Tasting Notes - {{language}}',
  addTranslationButton: '+ Add Translation'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.BARLEY, {
  [BARLEY_FIELDS.BARLEY_VARIETY]: 'Barley Variety',
  [BARLEY_FIELDS.FARM_NAME]: 'Farm Name',
  [BARLEY_FIELDS.FIELD_NAME]: 'Field Name',
  [BARLEY_FIELDS.MALTED_BY]: 'Malted By'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.MASHING, {
  [MASHING_FIELDS.MASH_OPERATOR]: 'Mash Operator',
  [MASHING_FIELDS.FIRST_TEMPERATURE]: 'Temperature 1st Water',
  [MASHING_FIELDS.SECOND_TEMPERATURE]: 'Temperature 2nd Water',
  [MASHING_FIELDS.THIRD_TEMPERATURE]: 'Temperature 3rd Water',
  [MASHING_FIELDS.GRAVITY]: 'Gravity'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION, {
  [FERMENTATION_FIELDS.FERMENTATION_OPERATOR]: 'Fermentation Operator',
  [FERMENTATION_FIELDS.AVERAGE_HOURS]: 'Average Hours',
  [FERMENTATION_FIELDS.TYPE_OF_YEAST]: 'Type of Yeast'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION, {
  [DISTILLATION_FIELDS.STILL_OPERATOR]: 'Still Operator',
  [DISTILLATION_FIELDS.MIN_HYDROMETER]: 'Min Hydrometer',
  [DISTILLATION_FIELDS.MAX_HYDROMETER]: 'Max Hydrometer'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.MATURATION, {
  [MATURATION_FIELDS.CASK_TYPE]: 'Cask Type',
  [MATURATION_FIELDS.WOOD_FILL]: 'WOOD/FILL',
  [MATURATION_FIELDS.SPIRIT_TYPE]: 'Spirit Type',
  [MATURATION_FIELDS.VINTAGE]: 'Vintage',
  [MATURATION_FIELDS.CASK_NO]: 'Cask NO',
  [MATURATION_FIELDS.MATURATION_FILE]: 'Maturation File'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.IMPORTER, {
  [IMPORTER_FIELDS.IMPORTERS_NAME]: "Importer's Name",
  [IMPORTER_FIELDS.WEBSITE]: 'Website',
  [IMPORTER_FIELDS.EMAIL_ADDRESS]: 'Email Address'
});

i18next.addResources('en', 'CheckboxPercentage', {
  label: 'Percentage'
});

i18next.addResources('en', NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY, {
  lewisHamilton: 'Lewis Hamilton',
  kellyCombe: 'Kelly Combe',
  carnieHamilton: 'Carnie Hamilton',
  jessHamilton: 'Jess Hamilton',
  jasonMcCafferty: 'Jason McCafferty',
  keithJohnston: 'Keith Johnston',
  jennyKarlsson: 'Jenny Karlsson',
  hollyMcCafferty: 'Holly McCafferty',
  reeceDonaldson: 'Reece Donaldson',
  andyWilliamson: 'Andy Williamson',
  andrewColman: 'Andrew Colman'
});

i18next.addResources('en', 'operators', {
  markGiesler: 'Mark Giesler',
  gordonMackenzie: 'Gordon Mackenzie',
  rossHughes: 'Ross Hughes',
  nickyDocherty: 'Nicky Docherty',
  chrisAnderson: 'Chris Anderson',
  scottStewart: 'Scott Stewart',
  stewartConnor: 'Stewart Connor',
  alastairMackay: 'Alastair Mackay',
  djCameron: 'DJ Cameron',
  allyFisher: 'Ally Fisher',
  louisMackenzie: 'Louis Mackenzie',
  paulMilne: 'Paul Milne'
});

i18next.addResources('en', 'Templates', {
  [TEMPLATES_OPTIONS.LIMITED_RELEASE]: 'Limited Release',
  [TEMPLATES_OPTIONS.SINGLE_CASK]: 'Single Cask',
  [TEMPLATES_OPTIONS.SINGLE_MALT]: 'Single Malt'
});
